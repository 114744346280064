import React, { Component, useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css"

function AboutMe() {
  return (
    <div class="container">
      <h3 class="title">About me</h3> 
      <div class="row">
        <div class="col s6 m4 offset-s3 offset-m4" data-aos="fade-down">
          <img src={process.env.PUBLIC_URL + "me2.jpg"} class="responsive-img circle" height="50" alt='Daniil Zimerman / Даниил Цимерман'></img>
        </div>
      </div>
      <div class="col s7" data-aos="fade-up">
          <p class="flow-text">Hello! I'm Daniil Zimerman, machine learning engineer on Mondays, backend developer on Fridays, and a product-market fit seeker on Sundays.</p>
        </div>
    </div>
  )
}

const links = [
  {"type": "telegram", "link": "https://t.me/solemn_leader", "text": "telegram"},
  {"type": "instagram", "link": "https://instagram.com/solemn_leader", "text": "instagram"},
  {"type": "vk", "link": "https://vk.com/solemn_leader", "text": "vk"},
  {"type": "linkedin", "link": "https://www.linkedin.com/in/solemn-leader/", "text": "linkedin"},  
  {"type": "github", "link": "https://github.com/solemn-leader", "text": "github"},  
  {"type": "envelope", "link": "mailto:solemnda@gmail.com", "text": "email"},
  {"type": "file-text", "link": "https://docs.google.com/document/d/1NDgEZasa5pOJ_xrOjheTqie9MfDG38zug8kNcdPoffY/edit?usp=sharing", "text": "ML engineer CV"},
]

function MyLinks(links) {
  return (
    <div class="container">
      <h3 class="title">Links</h3>
      <div class="center-align" data-aos="fade-right">
        {links.map(link => <><a class="waves-effect waves-light btn black margin-btn" href={link.link} target="_blank">
        <i class={"fa fa-" + link.type}></i> {link.text} </a>
        </>
          )}
      </div>
    </div>
  )
}

function MyContent() {
  useEffect(() => {
    Aos.init({ duration: 2000, once: true });
  }, []);
  return (
    <div class="container">
      <h3>Content</h3>
      <div class="row" data-aos="fade-left">
        <div class="card">
          <div class="card-image">
            <div class="video-container">
              <iframe src="https://www.youtube.com/embed/cMk4Qye-1N0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div class="card-action black-text">
            <h6> My speech on how to create nontoxic chatbots (RU) </h6>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-right">
        <a href="https://news.itmo.ru/en/university_live/achievements/news/9976/" target="_blank">
        <div class="card">
        <div class="card-image">
          <img src={process.env.PUBLIC_URL + "anotherme.png"}></img>
        </div>
        <div class="card-action black-text">
        <u><h6> My interview after AI journey victory <i class="fa fa-external-link" aria-hidden="true"></i></h6></u>
        </div>
      </div>
        </a>
      </div>
      <div class="row" data-aos="fade-left">
        <div class="card">
        <div class="card-image">
        <div class="video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/7yeGLKKVS6I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>  
        </div>
        <div class="card-action black-text">
            <h6> <b>Data Fest</b>: How I started IT career being a 17yo teen living in a small town (RU) </h6>
        </div>
      </div>
      </div>
      <div class="row" data-aos="fade-right">
        <div class="card">
          <div class="card-image">
            <div class="video-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/-sjr4stWJaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
          </div>
          <div class="card-action black-text">
            <h6> <b>DUMP</b>: How to create few-shot intent classification system (RU) </h6>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-left">
        <a href="https://habr.com/en/company/tinkoff/blog/696756/" target="_blank">
          <div class="card">
            <div class="card-image">
              <img src="https://habrastorage.org/r/w1560/getpro/habr/upload_files/e61/ccf/828/e61ccf828fe0dd0ac0cc798578688bb4.png"></img>
            </div>
            <div class="card-action black-text">
              <u><h6> <b>Habr</b>: How to create few-shot intent classification system (RU) <i class="fa fa-external-link" aria-hidden="true"></i></h6></u>
            </div>
          </div>
        </a>
      </div>
      <div class="row" data-aos="fade-right">
        <div class="card">
          <div class="card-image">
            <div class="video-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/24qzhhluHWA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
          </div>
          <div class="card-action black-text">
            <h6> <b>PyCon master class</b>: creating chatbot within 3 hours (RU) </h6>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-left">
        <div class="card">
          <div class="card-image">
            <div class="video-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/oOUswl0GxS0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
          </div>
          <div class="card-action black-text">
            <h6> <b>Sber AI Bootcamp</b>: Introduction to recurrent neural networks (RU) </h6>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-right">
        <div class="card">
          <div class="card-image">
            <div class="video-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/Nl85GXAiQkA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
          </div>
          <div class="card-action black-text">
            <h6> <b>Advanced DL</b> lecture: Introduction to NLP: Tokens, tf-idf, bpe, word2vec, RNNs (RU) </h6>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-left">
        <div class="card">
          <div class="card-image">
            <div class="video-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/kJmABeZPYns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      </div>
          </div>
          <div class="card-action black-text">
            <h6> <b>Advanced DL</b> seminar: Introduction to NLP: Tokens, tf-idf, bpe, word2vec, RNNs (RU) </h6>
          </div>
        </div>
      </div>

    </div>
  )
}

function Footer() {
  return (
  <footer class="page-footer black">
    <div class="footer-copyright black white-text">
      <div class="container">
      © Daniil Zimerman
      </div>
    </div>
  </footer>
);
}


function App() {
  return (
    [
      <main>
        <div class="container">
          {AboutMe()}
          {MyLinks(links)}
          {MyContent()}
        </div>
      </main>,
      <br></br>,
      Footer()
    ]
  );
}

export default App;
